.wrap {
	margin: 1.5rem auto;
}

.content {
	width: 100%;
	margin: 0 auto;
	max-width: 600px;
	.main {
		padding-right: 0;
		padding-left: 0;
	}
	img {
		display: block;
		height: auto;
		max-width: 100%;
	}
}

.entry-title, .page-header h1 {
	font-size: 0;
	width: 1px;
	height: 1px;
	display: inline-block;
	overflow: hidden;
	position: absolute!important;
	border: 0!important;
	padding: 0!important;
	margin: 0!important;
	clip: rect(1px,1px,1px,1px);
}

@include media-breakpoint-up(lg) {
	.wrap {
		margin-top: 3rem;
	}
	.content {
		width: 90%;
		max-width: 690px;
	}
	.fullscreen-true {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0px;
		top: 0px;
		z-index: -1;
		img {
			width: 100%;
			height: 100%;
			opacity: 0.3;
		}
	}
}

@include media-breakpoint-up(xl) {
	.content {
		max-width: 717px;
	}
}
