.navbar {
	padding: 0 0 .5rem 0;
}

.navbar-brand {
	color: $grey-color;
	font-family: $font-family-menu;
	font-size: 2rem;
	margin-right: 2rem;
	&:hover {
		color: darken($grey-color, 10%);
	}
}

header a {
	font-family: $font-family-menu;
	&:hover {
		color: $gold-color;
		text-decoration: none;
	}
}

.nav > li > a {
	color: $gold-color;
	font-size: 1.7em;
	padding: .35rem 0 0;
}

.nav > .show > a {
	color: $gold-color;
}

.dropdown-menu {
	background: white;
	border: 0;
	border-radius: 0;
	a {
		color: $body-color;
		font-size: 1.2rem;
		padding: 0;
	}
}

.navbar-expand-lg .navbar-nav .dropdown-menu .nav-link {
	padding-right: 0;
	padding-left: 0;
}

.navbar-toggler {
	color: $navbar-light-color;
	border-color: $navbar-light-toggler-border-color;
}

.navbar-toggler-icon {
	background-image: $navbar-light-toggler-icon-bg;
}

.dropdown {
	margin-right: .5rem;
}

.dropdown-toggle:after {
	content: none;
}

.lang-switch {
	text-align: right;
	margin-top: .5rem;
}

.language-chooser {
	margin-bottom: 0;
	li {
		display: inline-block;
		&:first-of-type a::after {
			content: '/';
			color: $grey-color;
			margin-left: .2rem;
		}
	}
	a {
		color: $grey-color;
		font-size: 1rem;
	}
	.active a {
		color: $gold-color;
	}
}

.dropdown-menu {
	display: block;
	padding: .2rem 0 .4rem .2rem;
	a {
		line-height: 2rem;
	}
}

.dropdown-toggle {
	cursor: default;
}

@include media-breakpoint-up(md) {
	.navbar-brand {
		font-size: 2.5rem;
	}
}

@include media-breakpoint-up(lg) {
	.nav > li > a {
		background: $body-color;
		color: white;
		font-size: 1.1rem;
		padding: 0 .35rem;
	}
	.navbar-brand {
		font-size: 1.8rem;
	}
	.dropdown-menu {
		display: none;
		padding: .2rem .35rem .35rem;
		a {
			font-size: .875rem;
			line-height: $line-height-base;
		}
	}
	.dropdown-toggle {
		cursor: pointer;
	}
}

@include media-breakpoint-up(xl) {
	.nav > li > a {
		font-size: 1.25rem;
	}
	.navbar-brand {
		font-size: 2.3rem;
	}
}
