// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$body-color: #0C3734;
$grey-color: #A9A9A9;
$gold-color: #f6cd5b;

// Fonts
$font-family-sans-serif:      'Roboto', Helvetica, Arial, sans-serif !default;
$font-family-menu:           	'Open Sans Condensed', sans-serif !default;

$font-size-base: .875rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1rem !default;

$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

// Links
$link-color: $gold-color;

// Grid
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1100px,
	xl: 1200px
) !default;

$container-max-widths: (
	sm: 576px,
	md: 768px,
	lg: 1100px,
	xl: 1200px
) !default;

// Gallery
$carousel-control-color: $body-color;
