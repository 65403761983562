.table td, .table th {
	border-top: 0;
	padding: .5rem;
}

.table span {
	display: block;
}

.project-description, .project-description a {
	color: $grey-color;
}

.project-description p {
	margin-bottom: 0;
}

@include media-breakpoint-up(lg) {
	.project-description {
		font-size: .75rem;
	}
}
