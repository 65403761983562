.entry-gallery {
	margin: 2rem 0;
}
.gallery-box {
	margin-bottom: 0.75rem;
}
.gallery-box a {
	text-decoration: none;
}

.image-caption {
	color: $body-color;
	display: block;
	font-size: .8rem;
	font-style: italic;
	line-height: 1rem;
	margin-top: .3rem;
}

.col-lg-3 {
	padding-right: 10px;
	padding-left: 10px;
}

.slick-prev,
.slick-next {
	z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  // Use flex for alignment (1-3)
  display: flex; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  justify-content: center; // 3. horizontally center contents
  width: $carousel-control-width;
  color: $carousel-control-color;
  text-align: center;
  opacity: $carousel-control-opacity;
  @include hover-focus {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: .9;
  }
}
.slick-prev {
  left: -3rem;
}
.slick-next {
  right: -3rem;
}

// Icons for within
.slick-prev,
.slick-next {
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.slick-prev {
  background-image: $carousel-control-prev-icon-bg;
}
.slick-next {
  background-image: $carousel-control-next-icon-bg;
}

.slick-arrow {
	border: 0;
	cursor: pointer;
	font-size: 0;
	position: absolute;
	top: 46%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.slick-disabled {
	display: none !important;
}

.gallery-slider-thumbnails {
	display: none;
}

@include media-breakpoint-up(lg) {
	.gallery-comic, .gallery-carousel {
		margin-bottom: 30px;
		right: 0;
		left: 0;
		position: absolute;
		width: 830px;
	}
	.gallery-carousel, .gallery-slider-thumbnails {
		.carousel-slide {
			padding-right: 10px;
		}
		.slick-prev {
			left: -30px;
		}
		.slick-next {
			right: -25px;
		}
	}
	.gallery-slider-thumbnails {
		display: block;
		margin-top: 15px;
		.carousel-slide {
			cursor: pointer;
		}
	}
}
